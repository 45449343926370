<template>
  <div v-loading="loading">
    <section>
      <ui-list
        v-if="activeCourses"
        :heading="`${$tc('misc.active', 1)} ${$tc('models.course', 1)}`"
        :headings="[
          $tc('labels.course.name', 1),
          $tc('labels.course.completion', 1),
          ''
        ]"
        :items="activeCourses"
      >
        <template v-slot:default="props">
          <ui-list-data
            :text="
              props.item.title.filter(t => t.lang === $i18n.locale)[0].value
            "
            :infoHtml="
              props.item.description.filter(t => t.lang === $i18n.locale)[0]
                .value
            "
          >
          </ui-list-data>
          <ui-list-data-progress
            :percentage="props.item.progress.progressPercentage"
          >
          </ui-list-data-progress>
          <ui-list-data small>
            <ui-link
              type="primary"
              size="small"
              uppercase
              @click="handleClick(props.item)"
              >{{ $t('labels.course.participation') }}</ui-link
            >
          </ui-list-data>
        </template>
      </ui-list>
    </section>

    <section class="mt-6">
      <ui-list
        v-if="inactiveCourses && inactiveCourses.length > 0"
        :heading="`${$tc('misc.course_without_participation', 1)}`"
        :headings="[$tc('models.course', 1), '']"
        :items="inactiveCourses"
      >
        <template v-slot:default="props">
          <ui-list-data
            :text="
              props.item.title.filter(t => t.lang === $i18n.locale)[0].value
            "
            :infoHtml="
              props.item.description.filter(t => t.lang === $i18n.locale)[0]
                .value
            "
          >
          </ui-list-data>
          <ui-list-data small>
            <ui-link
              type="primary"
              size="small"
              uppercase
              @click="handleClick(props.item)"
              >{{ $t('labels.course.participation') }}</ui-link
            >
          </ui-list-data>
        </template>
      </ui-list>
    </section>
  </div>
</template>

<script>
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';
import UiLink from '@/components/ui/UiLink';
import UiListDataProgress from '@/components/ui/UiListDataProgress';
import {getCourses, getDepartment} from '../api';

export default {
  props: {
    department: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      courses: null,
      loading: false,
      unstarted: [],
      activeCourses: [],
      inactiveCourses: []
    };
  },

  components: {
    UiList,
    UiListData,
    UiListDataProgress,
    UiLink
  },

  methods: {
    async getCourses() {
      this.loading = true;
      try {
        this.loading = false;
        const courses = await getCourses(this._props.department.id);
        let active = [];
        let inactive = [];
        if (courses) {
          courses.map(c => {
            if (c.progress && c.progress.assignedCount) {
              active.push(c);
            } else {
              inactive.push(c);
            }
          });
        }

        this.activeCourses = active;
        this.inactiveCourses = inactive;
      } catch (error) {
        this.loading = false;
        this.$message({
          message: this.$tc('errors.load', {
            msg: this.tc('models.course', 2)
          }),
          type: 'error'
        });
      }
    },

    handleClick(item) {
      this.$router.push({
        name: 'inspect-department-course',
        params: { cid: item.id }
      });
    },

    getPercentage(users) {
      if (users.length === 0) return 0;
      const hasCourse = users.length;
      const completed = users.filter(u => u.completed).length;
      const percentage = Math.floor((completed / hasCourse) * 100);
      return percentage;
    }
  },

  created() {
    this.getCourses();
  },

  async mounted() {
    let department = await getDepartment(this.$route.params.id);
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.departments", link: "/app/departments/"},
      {name: department.name, link: "/app/departments/" + department.id + "/show"},
      {name: "models.a_course", link: "/app/departments/" + department.id + "/show"}
    ]);
  },
};
</script>
