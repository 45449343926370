<template>
  <td :class="{ small: small }">
    <span class="flex">
      <!-- Contains the format 2 / 19 (completed count / assigned count) -->
      <span
        class="flex-initial pr-2 font-semibold self-center"
        v-if="progressDetails !== null"
      >
        {{ progressDetails.completedCount }} /
        {{ progressDetails.assignedCount }}
      </span>
      <div class="flex-auto flex">
        <ui-progress-bar class="flex-auto self-center" :percentage="percentage"/>
      </div>
    </span>

    <div v-if="$slots.default" class="flex">
      <slot></slot>
    </div>
  </td>
</template>

<script>
import UiProgressBar from "@/components/ui/UiProgressBar";

export default {
  components: {UiProgressBar},
  props: {
    type: {
      type: String,
      default: 'default'
    },
    percentage: {
      type: Number,
      required: true,
      default: 0
    },
    small: {
      type: Boolean,
      default: false
    },
    progressDetails: {
      type: Object,
      default: null
    }
  },

  computed: {
    styles() {
      if (this.type === 'default') {
        return ['block'];
      }

      if (this.type === 'tag') {
        return [
          'whitespace-no-wrap',
          'text-xs',
          'font-bold',
          'rounded-full',
          'px-3',
          'py-1',
          'bg-blue-100',
          'text-gray-600'
        ];
      } else {
        return [];
      }
    },
    status() {
      if (this.percentage === 100) return 'success';
      if (this.percentage >= 0 && this.percentage <= 33) return 'exception';
      if (this.percentage > 33 && this.percentage <= 66) return 'warning';
      else return false;
    }
  },

  methods: {
    handleClick() {
      this.$emit ('click');
    }
  }
};
</script>
