var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('section',[(_vm.activeCourses)?_c('ui-list',{attrs:{"heading":`${_vm.$tc('misc.active', 1)} ${_vm.$tc('models.course', 1)}`,"headings":[
        _vm.$tc('labels.course.name', 1),
        _vm.$tc('labels.course.completion', 1),
        ''
      ],"items":_vm.activeCourses},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":props.item.title.filter(t => t.lang === _vm.$i18n.locale)[0].value,"infoHtml":props.item.description.filter(t => t.lang === _vm.$i18n.locale)[0]
              .value}}),_c('ui-list-data-progress',{attrs:{"percentage":props.item.progress.progressPercentage}}),_c('ui-list-data',{attrs:{"small":""}},[_c('ui-link',{attrs:{"type":"primary","size":"small","uppercase":""},on:{"click":function($event){return _vm.handleClick(props.item)}}},[_vm._v(_vm._s(_vm.$t('labels.course.participation')))])],1)]}}],null,false,283132575)}):_vm._e()],1),_c('section',{staticClass:"mt-6"},[(_vm.inactiveCourses && _vm.inactiveCourses.length > 0)?_c('ui-list',{attrs:{"heading":`${_vm.$tc('misc.course_without_participation', 1)}`,"headings":[_vm.$tc('models.course', 1), ''],"items":_vm.inactiveCourses},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ui-list-data',{attrs:{"text":props.item.title.filter(t => t.lang === _vm.$i18n.locale)[0].value,"infoHtml":props.item.description.filter(t => t.lang === _vm.$i18n.locale)[0]
              .value}}),_c('ui-list-data',{attrs:{"small":""}},[_c('ui-link',{attrs:{"type":"primary","size":"small","uppercase":""},on:{"click":function($event){return _vm.handleClick(props.item)}}},[_vm._v(_vm._s(_vm.$t('labels.course.participation')))])],1)]}}],null,false,121922953)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }